<template>
    <div class="kiu-steps-guide container">
        <div class="row">
            <div class="col-12 col-lg-4 kiu-steps-guide__column">
                <media-only desktop>
                    <svg class="kiu-steps-guide__icon">
                        <use :xlink:href="percentBadgeSVG + '#percentBadgeSVG'"></use>
                    </svg>
                </media-only>

                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header1') }}</header>

                <p>{{ $t('GuideSteps.step1') }}</p>
            </div>

            <div class="col-12 col-lg-4 kiu-steps-guide__column">
                <media-only desktop>
                    <svg class="kiu-steps-guide__icon">
                        <use :xlink:href="redeemGiftSVG + '#redeemGiftSVG'"></use>
                    </svg>
                </media-only>

                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header2') }}</header>

                <translate tag="p" path="GuideSteps.step2">
                    <template #bold>
                        <strong>{{ $t('Generic.validUp3Years') }}</strong>
                    </template>
                </translate>
            </div>

            <div class="col-12 col-lg-4 kiu-steps-guide__column">
                <media-only desktop>
                    <svg class="kiu-steps-guide__icon">
                        <use :xlink:href="caseCardSVG + '#caseCardSVG'"></use>
                    </svg>
                </media-only>

                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header3') }}</header>

                <p>{{ $t('GuideSteps.step3') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import percentBadgeSVG from '@/themes/kurzinurlaub/assets/icons/percent_badge.svg';
import redeemGiftSVG from '@/themes/kurzinurlaub/assets/icons/redeem_gift.svg';
import caseCardSVG from '@/themes/kurzinurlaub/assets/icons/case_card.svg';

export default {
    name: 'KIUThreeStepGuide',
    data() {
        return {
            percentBadgeSVG,
            redeemGiftSVG,
            caseCardSVG
        };
    }
};
</script>

<style scoped lang="scss">
.kiu-steps-guide {
    text-align: center;
    font-size: clamp(0.875rem, 0.8732rem + 0.1585vw, 1rem);
    margin-top: 1rem;

    &__column {
        display: flex;
        gap: 0.625rem;
        flex-direction: column;
    }

    &__header,
    &__icon {
        color: var(--secondary);
    }

    &__header {
        font-weight: bold;
        text-transform: uppercase;
    }

    &__icon {
        height: 30px;
    }
}
</style>
