<template>
    <div class="kiu-steps-guide full-bleed">
        <div class="row">
            <div class="kiu-steps-guide__item">
                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header1.mobile') }}</header>
            </div>

            <div class="kiu-steps-guide__item">
                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header2.mobile') }}</header>
            </div>

            <div class="kiu-steps-guide__item">
                <header class="kiu-steps-guide__header">{{ $t('GuideSteps.header3.mobile') }}</header>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'KIUThreeStepGuideMobile'
};
</script>

<style scoped lang="scss">
.kiu-steps-guide {
    text-align: center;
    font-size: clamp(0.875rem, 0.8732rem + 0.1585vw, 1rem);
    position: relative;
    background: var(--footer-bg-color);
    height: 50px;

    &__item {
        display: flex;
        gap: 0.625rem;
        align-items: center;
        justify-content: center;
        padding: 0 15px;
        position: absolute;
        inset: 0;
    }

    &__header {
        font-weight: bold;
        text-transform: uppercase;
        color: var(--white);
    }

    $animation-duration: 15s;
    $slides: 3;
    //$keyframe-percentage: ($amount-of-slides * 100) / $animation-duration * 1%;

    &__item {
        @for $i from 1 through $slides {
            &:nth-child(#{$i}) {
                animation: fade#{$i} $animation-duration infinite cubic-bezier(0.65, 0.01, 0.32, 1);
            }
            @keyframes fade#{$i} {
                @for $j from 0 through $slides {
                    #{percentage($j / $slides)} {
                        opacity: if($i==$j + 1 or ($i==1 and $j==$slides), 1, 0);
                    }
                }
            }
        }
    }
}
</style>
